.hideClass {
    display: none;
  }
  a{cursor: pointer;}
  #load {
    /* visibility: hidden; */
    width: 100%;
    left: 0;
    height: 100%;
    position: fixed;
    z-index: 9999;
    background: url("../../public/images/ajax-loader.gif") no-repeat center center
      rgba(255, 255, 255, 0.6);
  }
  .load {
    /* visibility: hidden; */
    width: 100%;
    left: 0;
    height: 100%;
    position: fixed;
    z-index: 9999;
  }
  .error{color:#f00;text-align: center;}
  .rounded-circle {
    border-radius: 50%!important
  }
  label{width: 100%;}
  .tabs a.active{color: #c04e3e}
  .form-forget{
    max-width: 500px;margin: auto
  }
  .form-forget label{
    left: 40px;
  }
  .form-forget .input-group-text{
    height: 58px;
    cursor: pointer;
  }
  .paginationlist li {
    list-style-type: none;
    float: left;
    padding-right: 2px;
  }
  .paginationlist li a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color 0.3s;
    border: 1px solid #ddd;
  }
  
  .paginationlist li a.selected {
    background-color: #212529;
    color: white;
    border: 1px solid #212529;
  }
  .brackword{
    width:250px!important;
    word-wrap: break-word!important;
  }
  .message-green{color:green;text-align:left;font-size:24px;text-align: center;}
  .view-pnr-link{color:#d66f47;}

  .BookingConfirm{
    box-shadow: 0px 0 5px rgba(0, 0, 0, 0.3);

}
.travelDetail .startTime.small{
  width: 12% !important;
  padding-right: 3%;
}
.travelDetail  .heading {
  padding: 10px 47px;
}
.travelDetail .innerCont{
  padding: 40px 77px;
}
.guestSelect .btn-reset{
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
}
.guestSelect .btn-primary{
  padding: 5px 10px;
  font-size: 16px;
}
.BookingConfirm .totalTime, .BookingConfirm .startTime, .BookingConfirm .endTime {
  display: inline-block;
  flex: auto !important;
  width: auto !important;
  position: relative;
  width: 15% !important;
}
.BookingConfirm .totalTime {
  text-align: center;
}
.BookingConfirm .endTime {
padding-left: 3%;
}
.travelDetail .innerCont {
  padding: 20px 47px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  position: relative;
}
.flightTimeTable p {
  font-size: 12px;
}
.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d!important;
}
.totalTime,
.startTime,
.endTime {
    display: inline-block;
    flex: auto !important;
    width: auto !important;
    position: relative;
    width: 32% !important;
}
.startTime.small{
    width: 20% !important;
}

.totalTime::after {
    position: absolute;
    content: "";
    top: 11px;
    right: 0;
    border-top: 1px solid #ccc;
    width: 21%;
    z-index: 0
}

.totalTime::before {
    position: absolute;
    content: "";
    top: 11px;
    left: 0;
    border-top: 1px solid #ccc;
    width: 21%;
    z-index: 0
}

.dot-vactor:before, .dot-vactor:after{
  content: '';
  width: 5px;
  height: 5px;
  position: absolute;
  top: 9px;
  background: #ccc;
  border-radius: 50%;
}
.dot-vactor:before {
  left: 0;
}
.dot-vactor:after {
  right: 0;
}

.bottom_pane {
  text-align: center;
  padding: 25px 0 10px;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
}
.travel_info {
  padding: 0 20px 20px;
}
table {
  border-collapse: collapse;
  width: 100%;
}
td, th {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.title {
  background-color: #e08255;
  text-transform: uppercase;
  color: #fff;
  border-bottom: 0;
}
.disabled-link {
  pointer-events: none;
}


  
  